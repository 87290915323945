import React from "react";
import { graphql, PageProps } from "gatsby";
import { RTF } from "../../../elements";
import { Query } from "../../../generated/graphql-types";
import { Hero } from "../../../components";
import { buildMenu, Layout } from "../../../layouts";
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import { getImage } from "@bond-london/gatsby-graphcms-components";

const component: React.FC<PageProps<Pick<Query, "graphCmsInsight">>> = ({
  path,
  data: { graphCmsInsight: insight },
}) => {
  if (!insight) return null;
  const summaryRTF = getCleanedRTF(insight.summary);
  const contentRTF = getCleanedRTF(insight.content);
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(insight.menu)}
      footerMenu={buildMenu(insight.footerMenu)}
      keywords={insight.keywords}
      title={insight.title}
      description={insight.description}
    >
      <Hero
        title={insight.title}
        image={getImage(insight.heroImage)}
        short={true}
        colour={insight.heroColour}
        showSearch={true}
      />
      <section className="py-section relative max-width z-10">
        <div className="content-grid grid-flow-row-dense">
          {summaryRTF && (
            <RTF className="col-start-1 col-span-full" content={summaryRTF} />
          )}
          {contentRTF && (
            <RTF className="col-start-1 col-span-full" content={contentRTF} />
          )}
        </div>
      </section>
    </Layout>
  );
};

export default component;

export const insightQuery = graphql`
  query InsightQuery($id: String) {
    graphCmsInsight(id: { eq: $id }) {
      menu {
        ...MenuFragment
      }
      content {
        cleaned
      }
      heroImage {
        ...ImageFragment
      }
      heroColour
      id
      slug
      summary {
        cleaned
      }
      title
      keywords
      description
    }
  }
`;
